import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BadgePage from 'src/components/badge-page';

export default function Page() {
    const { allStrapiLecture } = useStaticQuery(
        graphql`
            query lecturesQuery {
                allStrapiLecture {
                    lectures: nodes {
                        name
                        tags {
                            color
                            name
                        }
                        url
                    }
                }
            }
        `
    );

    const { lectures } = allStrapiLecture;

    return <BadgePage badges={lectures} title="Lectures I've Taken" />;
}
