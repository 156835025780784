import React from 'react';
import { BadgeObject } from 'src/utils/types';
import { ICONS } from '../utils/icons';
import TagBadge from './tag-badge';
import { sortBy } from 'lodash';

export default function BadgeCard(props: {
    badge: BadgeObject;
    updateFilterString?(s: string): void;
}) {
    const { badge } = props;

    return (
        <div
            className={
                'relative flex flex-col w-full h-36 md:h-32 lg:h-36 items-start ' +
                'justify-start p-2 sm:p-3 shadow text-gray-800 rounded-r border-0 ' +
                'border-l-4 bg-white border-gray-300'
            }
        >
            <div
                className={
                    'relative flex flex-row items-center ' +
                    'justify-center flex-shrink-0'
                }
            >
                {badge.url !== undefined && badge.url.includes('://') && (
                    <a
                        href={badge.url}
                        target='_blank'
                        rel='noopener noreferrer'
                    >
                        <div className='mr-1 fill-current w-7 h-7 no-selection'>
                            {ICONS.link}
                        </div>
                    </a>
                )}
                <div className='ml-1 text-base leading-5 sm:leading-6 font-weight-600'>
                    {badge.name}
                </div>
            </div>
            <div className={'self-stretch flex-grow'} />
            <div className='relative float-left w-full mt-2 space-x-1 space-y-1 no-selection'>
                {sortBy(badge.tags, ['name']).map(tag => (
                    <TagBadge
                        key={tag.name}
                        tag={tag}
                        updateFilterString={props.updateFilterString}
                    />
                ))}
            </div>
        </div>
    );
}
