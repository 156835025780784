import React from 'react';
import { TagObject } from '../utils/types';

export default function TagBadge(props: {
    tag: TagObject;
    updateFilterString?(s: string): void;
    selected?: boolean;
}) {
    const { tag } = props;

    let colors: string;

    switch (tag.color) {
        case 1:
            colors = 'bg-red-100 text-red-800';
            break;
        case 2:
            colors = 'bg-green-100 text-green-800';
            break;
        case 3:
            colors = 'bg-purple-100 text-purple-800';
            break;
        case 4:
            colors = 'bg-teal-100 text-teal-800';
            break;
        case 5:
            colors = 'bg-blue-100 text-blue-800';
            break;
        case 6:
            colors = 'bg-yellow-100 text-yellow-800';
            break;
        case 7:
            colors = 'bg-orange-100 text-orange-800';
            break;
        default:
            colors = 'bg-gray-100 text-gray-800';
            break;
    }

    return (
        <div
            className={
                'relative inline-block px-2 leading-6 rounded ' +
                'text-sm font-weight-600 text-opacity-90 ' +
                'transition-all duration-100 ' +
                `${colors} ` +
                (props.updateFilterString
                    ? 'cursor-pointer '
                    : 'cursor-default ') +
                (props.selected !== undefined ? 'ring-2 shadow ' : ' ') +
                (props.selected ? 'ring-blue-400 ' : 'ring-transparent ')
            }
            onClick={() => props.updateFilterString(tag.name)}
        >
            {tag.name}
        </div>
    );
}
