import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { BadgeObject } from 'src/utils/types';
import { ICONS } from '../utils/icons';
import BadgeCard from './badge-card';
import { concat, filter, sortBy, uniqBy } from 'lodash';
import { TagObject } from '../utils/types';
import TagBadge from 'src/components/tag-badge';
import TitleBar from './title-bar';
import MainContainer from 'src/components/main-container';

export default function BadgePage(props: {
    title: string;
    badges: BadgeObject[];
}) {
    const [badges, setBadges] = useState(props.badges);
    const [filterString, setFilterString] = useState('');

    useEffect(() => {
        if (filterString == '') {
            setBadges(props.badges);
        } else {
            setBadges(
                filter(
                    props.badges,
                    (b: BadgeObject) =>
                        filter(
                            b.tags,
                            (t: TagObject) => t.name === filterString
                        ).length != 0
                )
            );
        }
    }, [filterString]);

    const allTags = sortBy(
        uniqBy(
            concat([], ...props.badges.map((b: BadgeObject) => b.tags)),
            'name'
        ),
        ['name']
    );

    function updateFilterString(s: string) {
        if (s === filterString) {
            // Remove filter if clicked on the same badge again
            setFilterString('');
            cleanup();
        } else {
            setFilterString(s);
            window.addEventListener('keydown', keydown);
        }
    }

    function keydown(e: KeyboardEvent) {
        if (e.key === 'Escape') {
            setFilterString('');
            cleanup();
        }
    }

    function cleanup() {
        window.removeEventListener('keydown', keydown);
    }

    useEffect(() => {
        return cleanup;
    }, []);

    return (
        <MainContainer>
            <TitleBar
                title={props.title}
                backTo='/'
                clear={{ filterString, setFilterString }}
            />
            <div
                className={
                    'relative float-left w-full mt-2 space-x-2 space-y-2 no-selection mb-8 '
                }
            >
                {allTags.map((t: TagObject, i: number) => (
                    <TagBadge
                        tag={t}
                        key={t.name}
                        {...{ updateFilterString }}
                        selected={t.name === filterString}
                    />
                ))}
            </div>
            <div className='grid w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-3 gap-y-3'>
                {sortBy(badges, ['name']).map((b: BadgeObject, i: number) => (
                    <BadgeCard
                        key={b.name}
                        badge={b}
                        {...{ updateFilterString }}
                    />
                ))}
            </div>
        </MainContainer>
    );
}
